.paj4{
    background-color: #F2F2F2;
    padding: 1%;
    margin-left: 2%;
   
}
.colored{
    color: #828282;

}
.storename{
    color:#969696;
    font-weight: 700;
font-size: 24px;
}
.line{
    width: 95%;
    margin-left: 3%;
}
.tradercard{
    width: 96%;
    margin-left: 3%;
    flex-direction: column;
align-items: flex-end;
padding: 16px;
gap: 24px;
background: #FAFAFA;
box-shadow: 2px 4px 12px rgba(95, 95, 95, 0.25);
border-radius: 8px
}
.downloadbtn{
  
    background: #F7F7F7;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.09);
border-radius: 8px;
cursor: pointer;
}
.acceptBtn{
    background-color: #6FDB53
    ;
    color: white;
    /* padding: 25px; */
gap: 8px;
width: 130px;
height: 60px;
}
.acceptBtn:hover{
    background-color: #59c040
    ;
    color: white;
    /* padding: 25px; */
gap: 8px;
width: 130px;
height: 60px;
}

.rejectBtn{
    background-color: #EB384B

    ;
    color: white;
    /* padding: 25px; */
gap: 8px;
width: 130px;
height: 60px;
}
.rejectBtn:hover{
    background-color: #da2b3d

    ;
    color: white;
    /* padding: 25px; */
gap: 8px;
width: 130px;
height: 60px;
}