navbar.css‏
.childdiv{
    display: flex;
    position: fixed;
    top:0px
  
}
.rightnav{
display: flex;
}
.childDiv{
    width: 95%;
    display: flex;
    justify-content: space-between;
}
.noUsers{
display: flex;
}
.mainNavbar{
    
    height: 70px;
}
.navbtn :active{
    color: green;
background-color: rgba(0, 128, 0, 0);
}


.ant-menu-item,
.ant-menu-submenu-title {
    /* position: relative; */
    /* display: block; */
    margin: 0;
    padding: 0 0px;
    /* white-space: nowrap; */
    /* cursor: pointer; */
    /* transition: border-color .3s,background .3s,padding .3s cubic-bezier(.645,.045,.355,1); */
}

.signupbutton{
        background: #054b49;
            border-radius: 10px;
            color: #fff;
            height: 50px;
            letter-spacing: 0;
            text-align: center;
            width: 100px;
}
.link{
    text-decoration: none;
}
.li{
    text-decoration: none;
}
.ant-menu-item a:before {
    width: 90px;
        height: 50px;
        border-radius: 0px;
        color: #0F726E;
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0em;
        text-align: center;
}
.bggreen{
    background: #054b49;
        border-radius: 10px;
        color: #fff;
        height: 50px;
        letter-spacing: 0;
        text-align: center;
        width: 100px;
}
.li{
        width: 90px;
            height: 50px;
            border-radius: 0px;
            color: #0F726E;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 0em;
            text-align: center;
}
.searchbutton{

    width: 119px;
height: 33px;
background: #F7F7F7;
color: rgb(61, 59, 59);
box-sizing: border-box;

}
.searchbutton:hover{

    width: 119px;
height: 33px;
background: #000000;
color: rgb(255, 255, 255);
box-sizing: border-box;

}