.right-sidebar {
 
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 70px;
  bottom: 0;
  width: 20%; /* Adjust the width as needed */
  background-color: white;
  padding: 20px;
  border-left: 1px solid #f0f0f0;
}

.control {
  margin-top: 3%;
  height: 56px;
  background: #6b7280;
  border-radius: 50px 0px 0px 50px;
}

.notControl {
  margin-top: 3%;
  height: 50px;
  border-radius: 50px 0px 0px 50px;
}

ul li {
  text-decoration: none;
}

.right-sidebar li a {
  margin-top: 10px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #828282;
  padding: 10px 0;
  height: 50px;
  font-size: 1.2rem; /* adjust as needed */
  font-weight: 350;
}

.logOutButton {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #828282;
  padding: 10px 0;
  height: 50px;
  font-size: 1.2rem; /* adjust as needed */
}
.right-side-button {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
