

.register-container {
 
  padding: 0px;
}

.register-img-container {
  background-image: url('../../images/logo.png');
  background-size: auto;
  background-position: center;

  background-repeat: no-repeat;
}

.register-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

}

.bb{
    color: #f77867;
}

.password-requirements-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.password-requirements-list li {
  display: flex;
  align-items: center;
}

.password-requirements-list li span {
  margin-right: 5px;
}

.languageSwitch{
  width: 150px;
  float: right;
  background-color: #f77c65;
  margin: 1%;
}
.playstation-hand-icon {
  font-size: 24px;
  color: #ffffff;
  margin-right: 5px;
}
