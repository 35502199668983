.routes {
    display: flex;
    flex-wrap: wrap;
  }
  
  .sidebar {
    /* flex: 0 0 20%; */
    /* ... */
  }
  
  .width80 {
    flex: 0 0 80%;
    /* ... */
  }
  