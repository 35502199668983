.subPage{
   
   
    min-height: 100vh;
   
}
.subrow{
   
    padding-top: 15%;
}
.logoimg{
    height: 300px;
    background-image: url('../../images/logo.png');
    background-size: contain;
    
    background-position: center;
    background-repeat: no-repeat;
}
.sub{
    color: #424242;
    font-size: 20px;
    text-align: end;
    margin-top: -50px;
}
.sendData{
  /* Frame 238830 */


/* Auto layout */



justify-content: center;
align-items: center;
width: 95%;
gap: 8px;


height: 54px;



/* Linear */

background: linear-gradient(77.35deg, #F65F6F 4.4%, #F78164 90.83%);
border-radius: 8
}
.in{
    height: 50px;
}
.uploadimg{

    background: #FFFFFF;
    border: 4px dashed #CECECE;
    border-radius: 8px;    padding: 2%;
    padding-left: 10%;
    margin-left: 8%;
    width: 90%;

}