.plushH{
    height: 45px;
}
.rg{
    background-color: white;
    padding: 10px;
   
    border-radius: 8px;
    height: 68px;
}

#des{
    height: 100px;
}
.des{
    height: 100px;
}
.send{
    width: 300px;
    height: 60px;
}
.deleteIcon:hover{
color: red;
}
