.paj2{
    background-color: #F2F2F2;
    padding: 1%;
}
.th{
    text-align: end;
    font-weight: 400;

line-height: 18px;
/* identical to box height, or 150% */



/* Black/40% */

color: rgba(0, 0, 0, 0.4);

}

.paj3{
    background-color: #F2F2F2;
    padding: 1%;
   
}

.deleteIcon:hover{
    color:red;
    
}

/* .tac{
    border-bottom:3px solid silver ;
    margin-left: 3%;
} */

.invalid-input {
    border: 1px solid red;
  }
.notactiveStatus{
    color:#A7A7A7 ;
    /* border-bottom: 2px solid #6B7280; */
    font-size: large;
    cursor: pointer;
   text-align: end;
   font-size: 16px;
   margin-bottom: 11px;
}
.IMGS{
    position: absolute;
    top: 5%;
}
.activestatus{
    padding-bottom: 18px ;
    cursor: pointer;
    font-size: large;
    color: #6B7280 ;
    font-size: 16px;
    text-align: end;
    margin-bottom: 11px;
    border-bottom: 2px solid #6B7280;
  
}
.delrow{
    margin-bottom: 11px;
    padding-bottom: 11px;
    border-bottom: 3px solid #EEEEEE;
}