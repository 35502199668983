.loginpage{
    min-height: 100vh;
}
.formRow{
    margin-top: 15%;
}
.bg-img {
    background-image: url('../../images/logo.png');
    background-size: contain;
    
    background-position: center;
    background-repeat: no-repeat;
  }
  .emailinput{
    height: 50px;
    border-radius: 15px;
  }
  .passwordinput{
    height: 50px;
    border-radius: 15px;
  }
  .loginBtn{
   height: 60px;
   border-radius: 15px;

   font-weight: 600;
   font-size: x-large;
  }
  .languageSwitch{
    width: 150px;
    float: right;
    background-color: #f77c65;
    margin: 1%;
  }
  .playstation-hand-icon {
    font-size: 24px;
    color: #ffffff;
    margin-right: 5px;
  }
  